import React, { PureComponent } from 'react';
import { get, isEmpty, merge } from 'lodash';
import { array, bool, object, string } from 'prop-types';
import { Provider } from 'react-redux';
import MediaQuery from 'react-responsive';
import classNames from 'classnames';
import { api as collectApi } from '@fiverr-private/collect';
import { computeHighlights } from '@fiverr-private/experts';
import { GigPageContextProvider } from '@fiverr-private/gig_page_context';
import { Breadcrumbs } from '@fiverr-private/gig_page_nav';
import { getContext } from '@fiverr-private/fiverr_context';
import { DEFAULT_FIVERR_LOCALE } from '@fiverr-private/localization';
import { getTranslateOnLoad } from '@fiverr-private/machine_translation';
import { Stack } from '@fiverr-private/layout_components';
import { theme } from '@fiverr-private/theme';
import createGigStore from '../../store';
import {
    getCurrentPackage,
    getPackageById,
    isRecurringGig,
    isSinglePackage,
    isTriplePackage,
} from '../../reducers/packages';
import { isActiveMilestonePay, workProcessHasMilestones } from '../../reducers/workProcess';
import { updateCollections } from '../../actions/collections';
import { updateOnlineStatus } from '../../actions/isOnline';
import dynamicAllocationService from '../../utils/experiments/dynamicAllocationService';
import { manageRecentlyViewed } from '../../utils/recentlyViewedPages/guestPageViews';
import eventsReporter from '../../utils/reporter';

import { getSellerLanguageParams } from '../../utils/sellerLanguage';
import BrowserDetector from '../../utils/browserDetector';
import fetchIsOnline from '../../utils/fetchIsOnline';
import { inExperiment } from '../../utils/experiments';
import { FOXES_REMOVE_PCP_IN_GIG_PAGE_MOBILE } from '../../utils/experiments/experimentsEnum';

import { TOP_PAGE } from '../../utils/pageSections';
import { validateOrderDetails, validatePackagesTable } from '../../utils/mainComponentsValidator';
import BiEvents from '../../utils/biEvents';
import GigCurrencyConverter from '../../utils/gigCurrencyConverter';
import { MP_COMPARE_PACKAGES_VIEW, MP_SHOW_PAGE_NAME } from '../../utils/mixPanelEvents';
import {
    BQ_LOCAL_CATALOG_GIG_TRANSLATION_LAYER_VIEWED,
    BQ_SELLER_BUYER_SPEAK_SAME_LANGUAGE,
} from '../../utils/bigQueryEvents';
import { isGigApproved, isGigPaused } from '../../utils/gigStatus';
import { isFiverrChoice } from '../../utils/fiverrChoice';
import { addChoiceView, removeChoiceView } from '../../utils/fiverrChoice/choiceViewLocalStorageManager';
import { isLoggedInUser } from '../../utils/isLoggedInUser';
import Logger from '../../utils/logger';
import PathfinderWrapper from '../../utils/pathfinderWrapper';
import { BASIC_PACKAGE_ID } from '../../utils/packagesUtils/constants';
import { GIG_NAV_MENU } from '../../utils/events';
import { MEDIA_GRID_MD } from '../../utils/constants';
import { MIXPANEL_EVENT_TYPES } from '../../types/mixpanelEventTypes';
import { getMilestoneType } from '../../utils/workProcess';
import { metric } from '../../utils/metric';
import { ROLLOUTS } from '../../types/rollouts';
import ReturningBuyerSubscriptionModal from '../ReturningBuyerSubscriptionModal';
import OtherGigsBy from '../OtherGigsBy';
import PausedGig from '../PausedGig';
import DelayWarning from '../DelayWarning';
import Seo from '../Seo';
import Sidebar from '../Sidebar';
import PersonalizationWrapper from '../PersonalizationWrapper';
import FloatingContactSeller from '../ContactSeller/FloatingContactSeller';
import SellerBanner from '../SellerBanner';
import CouponBannerContainer from '../CouponBannerContainer';
import PromotionModal from '../PromotionModal';
import BusinessCollectionStepper from '../BusinessCollectionStepper';
import MachineTranslationPreviewBanner from '../Overview/MachineTranslationPreviewBanner';
import { PromotedCarousel } from '../PromotedCarousel/PromotedCarousel';
import { toggleUGC } from '../../actions/ugc';
import { buildTranslationLayerBQProps } from '../../utils/ugc/buildTranslationLayerBQProps';
import { buildSourceInfo } from '../../utils/ugc/buildSourceInfo';
import { initClientExperiments } from '../../utils/experiments/clientExperiments';
import { clientExperimentsConfig } from '../../utils/experiments/clientExperimentsConfig';
import { isExperientialGigTheme } from '../../utils/theme/isExperientialGigTheme';
import { NON_EXP_GIGS_SUB_CATEGORIES } from '../../utils/constants/nonExpGigsSubCategories';
import { isNonExperientialGigTheme } from '../../utils/theme/isNonExperientialGigTheme';
import { validateReviewsSnippet } from '../ReviewsSnippet/utils';
import { shouldShowPromotionModal } from '../PromotionModal/utils';
import { sectionScroller } from '../../utils/sectionScroller/sectionScroller';
import { Waypoint } from '../Waypoint/Waypoint';
import { ConsultationDataPropType } from '../ContactSeller/constants';
import { calcAgencyData } from '../../utils/calcAgenciesData/calcAgenciesData';
import { trackGigPageLoad, trackWorkflowGigPageRecordingRequested } from '../../utils/gtmTracking/gtmTracking';
import { getShouldDisplayFees } from '../../utils/priceUpfront';
import {
    BQ_DSA_NONCOMPLIANT_INTERACTION_STOPPED,
    LOCAL_CATALOG_PREVIEW_STATES,
    LoyaltyIndicationPropType,
    NON_EXP_BQ_PACKAGE_TABLE_SHOW_PARAMS,
    SellerCardPropType,
    SellerHourlyEligibilityPropType,
} from './constants';

import { buildShutterStockExtrasEventData } from './events';

import './style.scss';

class GigPage extends PureComponent {
    constructor(props) {
        super(props);

        const { general, overview } = this.props;

        if (!general) {
            // If general params is missing throw an error and don't render the page
            throw Error('Missing general data');
        }

        this.wrapChildren = this.wrapChildren.bind(this);
        this.shouldRenderNewProBanner = this.shouldRenderNewProBanner.bind(this);
        this.shouldShowProRebrandingLayout = this.shouldShowProRebrandingLayout.bind(this);
        this.isBusinessLayout = this.isBusinessLayout.bind(this);

        this.store = createGigStore(this.props);
        this.useStickyLayout = this.shouldUseStickyLayout();
        this.sectionScroller = sectionScroller();

        // used for unwrapping/rewrapping
        this.orphans = [];
        this.displayContents = true;
        this.isMediaMd = true;

        const packagesCount = get(this.store.getState(), 'packages.packageList.length', 3);

        const { locale, browserLanguage, countryCode } = getContext();
        this.sellerLanguageParams = getSellerLanguageParams({
            buyer: { locale, browserLanguage, countryCode },
            seller: overview.seller,
        });

        this.biEvents = new BiEvents({
            general,
            packagesCount,
            paymentType: this.paymentType,
        });
        this.logger = Logger.init();
        this.pathfinderWrapper = new PathfinderWrapper(this.logger);
        dynamicAllocationService.init();
    }

    getChildContext() {
        const {
            general,
            currency,
            choiceEligibilities,
            limitedEdition,
            currentUser,
            tags,
            overview,
            rollouts,
            seller,
            highlightsData,
            instantOrderSettings,
            gigSellerTranslation,
            gigTheme,
            machineTranslationFromServer,
            moreServices,
            topNav,
            coupon,
            studio,
            consultationData,
            sellerCard,
            loyaltyIndication,
            aiModels,
            regionCode,
            sellerHourlyEligibility,
            isRestrictedPage,
            isFloatingChatEnabled,
        } = this.props;

        const { abTests, locale, browserLanguage, countryCode } = getContext();
        const highlights =
            highlightsData &&
            computeHighlights({
                sellerData: seller,
                collaborateData: highlightsData.collaborateData,
                saveData: highlightsData.saveData,
                matchingIndustriesData: highlightsData.matchingIndustriesData,
                locale,
                browserLanguage,
                countryCode,
                hideProVerifiedHighlight: true,
            });

        const { agency, isAgency } = this.buildAgencyData();

        return {
            biEvents: this.biEvents,
            general,
            logger: this.logger,
            currencyConverter: new GigCurrencyConverter(currency),
            pathfinderWrapper: this.pathfinderWrapper,
            choiceEligibilities,
            limitedEdition,
            currentUser,
            seller: {
                achievement: sellerCard.achievement,
                profilePhoto: sellerCard?.profilePhoto,
                proficientLanguages: overview.seller.proficientLanguages,
                isVerified: seller.isVerified,
                photoUrl: seller.photoUrl,
                introVideoUrl: seller.introVideo?.url,
                highlights,
                displayName: seller?.user?.profile?.displayName,
                username: overview?.seller?.username || overview?.seller?.name,
                isPro: seller?.isPro,
                portfolios: seller?.portfolios,
                vacation: seller?.vacation,
                isOnVacation: seller?.isOnVacation,
                agency,
                isAgency,
                responseTime: sellerCard?.responseTime,
                sellerHourlyEligibility: sellerHourlyEligibility?.sellerHourlyEligible,
            },
            gig: {
                tags,
                instantOrderSettings,
                gigCollectedCount: topNav?.gigCollectedCount,
                rating: overview?.gig?.rating,
            },
            isBusinessLayout: this.isBusinessLayout(),
            isProRebrandingLayout: this.shouldShowProRebrandingLayout(),
            sellerLanguageParams: this.sellerLanguageParams,
            rollouts,
            gigSellerTranslation: {
                gigTitle: gigSellerTranslation?.localizedTitle,
                description: gigSellerTranslation?.localizedDescription,
                isEnabled: gigSellerTranslation?.isEnabled,
            },
            gigTheme,
            machineTranslationFromServer,
            moreServices,
            coupon,
            sectionScroller: this.sectionScroller,
            studio,
            consultationData,
            loyaltyIndication,
            shouldDisplayFees: getShouldDisplayFees({abTests, rollouts, regionCode}),
            aiModels,
            isRestrictedPage,
            isFloatingChatEnabled,
            sellerCard,
        };
    }

    componentDidMount() {
        this.setDisplayContentsSupport();
        this.wrapChildren();
        this.reportPageView();
        this.gtmReport();
        this.updateChoiceViewStorage();
        this.initBrowserDetector();
        this.fetchCollections();
        this.reportBuyerSpeakSellerLanguage();
        this.enrichReporter();
        this.initClientExperiments();

        this.manageRecentlyViewedPages();
        this.fetchOnlineStatus();
        this.reportStudioGig();
        this.initStickyTranslation();
        this.logWarningCustomExtra();

        this.reportDsaNonCompliantInteraction();
    }

    buildAgencyData() {
        const { seller } = this.props;
        const { isAgency, agency } = calcAgencyData({ seller });

        return { agency, isAgency };
    }

    initClientExperiments() {
        const { rollouts } = this.props;
        const store = this.store.getState();

        initClientExperiments(rollouts, clientExperimentsConfig, { store });
    }

    manageRecentlyViewedPages() {
        const { general } = this.props;
        const { gigId } = general;

        manageRecentlyViewed(gigId, this.pathfinderWrapper);
    }

    setDisplayContentsSupport() {
        if (window.CSS && window.CSS.supports) {
            this.displayContents = CSS.supports('display: contents');
        } else if (window.supportsCSS) {
            // Opera's old version of css detection
            this.displayContents = window.supportsCSS('display:contents');
        } else {
            this.displayContents = false;
        }
    }

    reportPageView() {
        const { packages } = this.store.getState();
        const { buyerAssumedLanguage, sellerCountryCode, sellerValidatedLanguages } = this.sellerLanguageParams;
        const { portfolio, reviews, notableClients, general } = this.props;
        const {
            seller: { highlights },
        } = this.getChildContext();
        const sellerLanguagesStringArray = sellerValidatedLanguages.map(
            (languageObj) => `${languageObj.name} - ${languageObj.level}`
        );
        const minPackagePrice = getPackageById(packages, BASIC_PACKAGE_ID)?.price;
        const shutterStockEventData = buildShutterStockExtrasEventData(packages);
        const withReviewsSnippet = this.shouldRenderReviews() && validateReviewsSnippet(reviews);

        this.biEvents.sendMixPanelEvent({
            mpEventType: MIXPANEL_EVENT_TYPES.PAGE_VIEW,
            eventName: MP_SHOW_PAGE_NAME,
            params: {
                buyerAssumedLanguage,
                sellerCountryCode,
                sellerValidatedLanguages: sellerLanguagesStringArray,
                minPackagePrice: minPackagePrice / 100,
                shutterStockEventData,
                isNex: NON_EXP_GIGS_SUB_CATEGORIES.has(general.subCategoryId),
                highlightsCount: highlights?.length ?? 0,
                withReviewsSnippet,
                withPortfolio: !!portfolio?.projectsCount,
                notableClientsCount: notableClients?.length ?? 0,
                reviewsCount: reviews?.reviewsCount ?? 0,
            },
        });
    }

    reportBuyerSpeakSellerLanguage() {
        const { shouldShowSellerLanguage, commonLanguage } = this.sellerLanguageParams;

        if (shouldShowSellerLanguage) {
            this.biEvents.sendBigQueryEvent({
                eventName: BQ_SELLER_BUYER_SPEAK_SAME_LANGUAGE,
                params: {
                    commonLanguage,
                },
            });
        }
    }

    reportStudioGig() {
        const { isStudio } = this.props.general;

        isStudio && metric.count('studio_gig.impression');
    }

    enrichReporter() {
        const { general } = this.props;
        const { gigId } = general;
        const enrichment = { gig: { id: gigId } };

        eventsReporter.enrich(enrichment);
    }

    gtmReport() {
        const { general, gigSellerTranslation } = this.props;
        const {
            gigId,
            gigTitle: originalGigTitle,
            isPro,
            categorySlug,
            subCategorySlug,
            nestedSubCategorySlug,
        } = general;
        const { packages } = this.store.getState();
        const basicPackage = getPackageById(packages, BASIC_PACKAGE_ID);
        const gigTitleParam = gigSellerTranslation?.gigTitle || originalGigTitle;

        trackGigPageLoad({
            gigId,
            gigTitle: gigTitleParam,
            isPro,
            categorySlug,
            subCategorySlug,
            nestedSubCategorySlug,
            basePrice: basicPackage ? basicPackage.price : 0,
        });

        if (this.hasWorkflow) {
            trackWorkflowGigPageRecordingRequested();
        }
    }

    updateChoiceViewStorage() {
        const { general, choiceEligibilities } = this.props;
        const { gigId } = general;
        const { queryParameters, abTests } = getContext();

        if (!isLoggedInUser()) {
            return;
        }

        isFiverrChoice({ queryParameters, abTests, choiceEligibilities })
            ? addChoiceView(gigId)
            : removeChoiceView(gigId);
    }

    initBrowserDetector() {
        BrowserDetector.init();
    }

    reportPackagesTableVisible() {
        const baseBqData = this.biEvents.getBigQueryEnrichmentData();

        this.biEvents.sendMixPanelEvent({ eventName: MP_COMPARE_PACKAGES_VIEW });
        this.biEvents.sendRawBigQueryEvent(
            merge({}, NON_EXP_BQ_PACKAGE_TABLE_SHOW_PARAMS, {
                user: { id: baseBqData.userId },
                seller: { id: baseBqData.sellerId },
                gig: { id: baseBqData.gigId },
                page: { ctx_id: baseBqData.pageCtxId },
            })
        );
    }

    isPro() {
        const { general } = this.props;

        return general.isPro;
    }

    isPageOwner() {
        const { sellerId } = this.props.general;
        const { userId } = getContext();

        return sellerId === userId;
    }

    canWrapUnwrapChildren() {
        return this.sidebar && this.main && !this.displayContents;
    }

    wrapChildren() {
        if (this.canWrapUnwrapChildren()) {
            this.isMediaMd ? this.rewrapChildren() : this.unwrapChildren();
        }
    }

    unwrapChildren() {
        if (!this.sidebar.firstChild) {
            return;
        }

        this.orphans = [];
        while (this.sidebar.firstChild) {
            this.orphans.push(this.sidebar.firstChild);
            this.main.insertBefore(this.sidebar.firstChild, this.main.firstChild);
        }
    }

    rewrapChildren() {
        for (let i = 0; i < this.orphans.length; i++) {
            this.sidebar.appendChild(this.orphans[i]);
        }
    }

    reportDsaNonCompliantInteraction() {
        if (this.props.isRestrictedPage) {
            this.biEvents.sendRawBigQueryEvent(
                merge({}, BQ_DSA_NONCOMPLIANT_INTERACTION_STOPPED, {
                    buyer: { id: this.props.buyer.id },
                    seller: { id: this.props.seller.user.id },
                    reason: 'gig_page',
                })
            );
        }
    }

    shouldRenderPackagesTable() {
        const { packages } = this.store.getState();

        return validatePackagesTable({ isTriplePackage: isTriplePackage(packages) });
    }

    isBusinessLayout() {
        const { experience = {} } = getContext();

        return experience.isBusiness;
    }

    get isCollectionStepperPopup() {
        const { rollouts } = this.props;

        return rollouts[ROLLOUTS.ZEBRAS_ACQ_COLLECTION_STEPPER];
    }

    get isDefaultMTEnabled() {
        const { rollouts } = this.props;

        return rollouts[ROLLOUTS.LIONS_DEFAULT_MT_TRANSLATION];
    }

    shouldShowProRebrandingLayout() {
        const { rollouts = {} } = this.props;

        return rollouts[ROLLOUTS.PRO_LAUNCH_MASTER];
    }

    shouldRenderOrderDetails() {
        const { packages } = this.store.getState();
        const { general } = this.props;
        const { isLimitedEdition } = general;

        return !isLimitedEdition && validateOrderDetails({ isSinglePackage: isSinglePackage(packages) });
    }

    shouldRenderComparePersonalizedSection() {
        const { abTests, isMobile } = getContext();
        const { general } = this.props;
        const { gigStatus, isLimitedEdition } = general;
        const isPageOwner = this.isPageOwner();
        const isApproved = isGigApproved(gigStatus);
        const shouldRemovePCP = inExperiment(FOXES_REMOVE_PCP_IN_GIG_PAGE_MOBILE, abTests) && isMobile;

        return !isPageOwner && isApproved && !isLimitedEdition && !shouldRemovePCP;
    }

    shouldRenderExplorePersonalizedSection() {
        const { abTests, isMobile } = getContext();
        const shouldRemovePCP = inExperiment(FOXES_REMOVE_PCP_IN_GIG_PAGE_MOBILE, abTests) && isMobile;

        return !this.isPageOwner() && !shouldRemovePCP;
    }

    shouldRenderProBanner() {
        return this.isPro() && !this.isBusinessLayout() && !this.shouldShowProRebrandingLayout();
    }

    shouldRenderNewProBanner() {
        const { seller } = this.props;
        return seller?.isPro;
    }

    shouldRenderLimitedEditionBanner() {
        const isPro = this.isPro();
        const { isLimitedEdition } = this.props.general;

        return isLimitedEdition && !isPro;
    }

    shouldRenderBottomCarousels() {
        const { isLimitedEdition } = this.props.general;

        return !isLimitedEdition;
    }

    shouldUseStickyLayout() {
        const { general } = this.props;

        return !general.isStudio;
    }

    shouldRenderReviews() {
        const { isLimitedEdition } = this.props.general;

        return !isLimitedEdition;
    }

    shouldRenderProVideo() {
        return this.isPro() && !this.isBusinessLayout() && !this.shouldShowProRebrandingLayout();
    }

    shouldRenderTranslationLayerPreviewBanner() {
        const { queryParameters } = getContext();
        const { forced_locale, preview_state } = queryParameters;

        return forced_locale && preview_state;
    }

    shouldRenderSellerBanner() {
        const shouldRenderTranslationLayerPreviewBanner = this.shouldRenderTranslationLayerPreviewBanner();
        const isPageOwner = this.isPageOwner();

        return isPageOwner && !shouldRenderTranslationLayerPreviewBanner;
    }

    shouldRenderDelayWarning({ isPaused }) {
        const { rollouts, seller } = this.props;
        const { isOnVacation: isSellerOOO } = seller;

        return rollouts[ROLLOUTS.FOXES_GIG_PAGE_RENDER_DELAY_MESSAGE] && !isPaused && !isSellerOOO;
    }

    shouldRenderMachineTranslationPreviewBanner() {
        const { queryParameters } = getContext();
        const { preview_state } = queryParameters;

        return preview_state === LOCAL_CATALOG_PREVIEW_STATES.MACHINE_TRANSLATION_PREVIEW;
    }

    get hasWorkflow() {
        const { workflow } = this.props;

        return !isEmpty(workflow);
    }

    get paymentType() {
        const { workflow } = this.props;
        const state = this.store.getState();
        const isRecurring = isRecurringGig(state.packages);
        const hasWorkflow = !isEmpty(workflow);
        const hasWorkProcess = workProcessHasMilestones(state);
        const activeMilestonePay = isActiveMilestonePay(state);

        return getMilestoneType({
            isRecurring,
            hasWorkflow,
            hasWorkProcess,
            activeMilestonePay,
        });
    }

    async fetchCollections() {
        if (isLoggedInUser()) {
            const { collections } = await collectApi.getCollections();
            this.store.dispatch(updateCollections(collections));
        }
    }

    async fetchOnlineStatus() {
        const isOnline = await fetchIsOnline({
            pathfinderWrapper: this.pathfinderWrapper,
            logger: this.logger,
        });

        this.store.dispatch(updateOnlineStatus(isOnline));
    }

    /**
     * initStickyTranslation activates MT-translation on load if conditions passed
     *  sticky translation is default naming across Fiverr and stands for using MT-translation on load
     *  sticky translation is available only for non en-US locale
     *  */
    async initStickyTranslation() {
        const {
            machineTranslationFromServer,
            general: { gigId },
        } = this.props;
        const isPageOwner = this.isPageOwner();

        const { locale, queryParameters } = getContext();
        const { forced_locale } = queryParameters;
        const isInSellerPreviewMode = isPageOwner && forced_locale;
        const isDefaultLocale = locale === DEFAULT_FIVERR_LOCALE;
        const isDefaultMTEnabled = this.isDefaultMTEnabled;
        const isMtOnServerEnabled = machineTranslationFromServer?.isEnabled;

        if (isDefaultLocale || isMtOnServerEnabled) {
            return;
        }

        /**
         * sticky translation is disabled in own gig preview
         * except of gig-translation preview mode
         * */
        if (isPageOwner && !isInSellerPreviewMode) {
            return;
        }

        const sourceInfo = buildSourceInfo({ componentName: GIG_NAV_MENU, gigId });
        const isTranslationOnLoadEnabled = getTranslateOnLoad(null, isDefaultMTEnabled) || isInSellerPreviewMode;

        if (isTranslationOnLoadEnabled) {
            try {
                this.store.dispatch(await toggleUGC(sourceInfo));
                if (this.isGigSellerTranslationLoaded()) {
                    this.reportGigSellerTranslationFeatureEnabledBIEvent();
                }
            } catch (ignore) {
                /* empty */
            }
        }
    }

    isGigSellerTranslationLoaded() {
        const { ugc = {} } = this.store.getState();
        const { ugcMap } = ugc;

        return ugcMap.translatedBySeller?.enabled;
    }

    reportGigSellerTranslationFeatureEnabledBIEvent() {
        const { ugc = {} } = this.store.getState();
        const { ugcMap } = ugc;
        this.biEvents.sendBigQueryEvent({
            eventName: BQ_LOCAL_CATALOG_GIG_TRANSLATION_LAYER_VIEWED,
            params: buildTranslationLayerBQProps(ugcMap.translatedBySeller),
        });
    }

    logWarningCustomExtra() {
        const { general } = this.props;
        const { gigId } = general;
        const state = this.store.getState();
        const { customExtras, features } = getCurrentPackage(state) || {};
        const featureIds = features?.map((feature) => feature.id);
        customExtras?.forEach(({ id, label, price, buyerTooltip }) => {
            const filterCondition = !!label && !!price && !!buyerTooltip && !featureIds?.includes(id);
            if (!filterCondition) {
                this.logger.warn('Get custom extras method filtered this extra', {
                    gigId,
                    customExtraId: id || 'Custom offer id does not exists',
                    label: label || 'Label does not exists',
                    price: price || 'Price does not exists',
                    buyerTooltip: buyerTooltip || 'BuyerTooltip does not exists',
                    distinctId: !featureIds?.includes(id),
                });
            }
        });
    }

    render() {
        const {
            children,
            description,
            sellerCard,
            portfolio,
            customOrder,
            otherGigs,
            general,
            studio,
            seo,
            faq,
            coupon,
            packages,
            seller,
            promotedGigs = [],
            gigTheme = 'experiential',
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ..._props // in order to silence propTypes errors, this is temp
        } = this.props;

        const { gigStatus, traffiqed, isStudio, gigId } = general;
        const { pathParameters, isTouch } = getContext();
        const { username } = pathParameters;
        const { info, services } = studio;
        const { packageList = [] } = packages;

        const isLoggedIn = isLoggedInUser();
        const isPageOwner = this.isPageOwner();
        const { promotionStatus } = this.props;
        const isPaused = isGigPaused(gigStatus, traffiqed);
        const shouldRenderDelayWarning = this.shouldRenderDelayWarning({ isPaused });
        const isPro = this.isPro();
        const { achievement, profilePhoto, hasProfilePhoto, responseTime } = sellerCard;
        const portfolioProps = { portfolio, achievement, isPro, profilePhoto, hasProfilePhoto };

        const shouldRenderSellerBanner = this.shouldRenderSellerBanner();
        const shouldRenderMachineTranslationPreviewBanner = this.shouldRenderMachineTranslationPreviewBanner();
        const collectionStepperPopup = this.isCollectionStepperPopup;
        const showPromotionModal = shouldShowPromotionModal(coupon, gigId);

        const mainContainerClassName = 'gig-page';
        const outerClasses = classNames(
            'gig-page-wrapper',
            'responsive',
            {
                'pro-gig': isPro,
                touch: isTouch,
            },
            {
                experiential: isExperientialGigTheme(gigTheme),
                'non-experiential': isNonExperientialGigTheme(gigTheme),
            }
        );

        const isDefaultLocale = getContext().locale === DEFAULT_FIVERR_LOCALE;

        const shouldRenderUGCTranslateButton = !isDefaultLocale && !isPageOwner;

        const layoutProps = {
            ...this.props,
            shouldRenderUGCTranslateButton,
            username,
            portfolioProps,
            info,
            services,
            isStudio,
            description,
            shouldRenderOrderDetails: this.shouldRenderOrderDetails.bind(this),
            shouldRenderPackagesTable: this.shouldRenderPackagesTable.bind(this),
            shouldRenderProBanner: this.shouldRenderProBanner.bind(this),
            shouldRenderReviews: this.shouldRenderReviews.bind(this),
            shouldRenderProVideo: this.shouldRenderProVideo.bind(this),
            shouldRenderLimitedEditionBanner: this.shouldRenderLimitedEditionBanner.bind(this),
            shouldRenderNewProBanner: this.shouldRenderNewProBanner.bind(this),
            setState: this.setState.bind(this),
            shouldRenderComparePersonalizedSection: this.shouldRenderComparePersonalizedSection.bind(this),
            useStickyLayout: this.useStickyLayout,
            reportPackagesTableVisible: this.reportPackagesTableVisible.bind(this),
        };

        return (
            <GigPageContextProvider {...this.getChildContext()}>
                <Provider store={this.store}>
                    <div className={outerClasses}>
                        <Seo seo={seo} faq={faq} packagesCount={packageList.length} />
                        <MediaQuery query={`(min-width: ${MEDIA_GRID_MD})`}>
                            {(matches) => {
                                this.isMediaMd = matches;
                                this.wrapChildren();

                                return false;
                            }}
                        </MediaQuery>

                        <Waypoint source={TOP_PAGE} />

                        <div className="gig-page-top">
                            <PromotedCarousel isLoggedIn={isLoggedIn} isTouch={isTouch} promotedGigs={promotedGigs} />
                            {shouldRenderSellerBanner && <SellerBanner promotionStatus={promotionStatus} />}
                            {shouldRenderMachineTranslationPreviewBanner && <MachineTranslationPreviewBanner />}
                            {!isPageOwner && isPaused && <PausedGig />}
                            {!isPageOwner && shouldRenderDelayWarning && <DelayWarning />}
                            {coupon && <CouponBannerContainer coupon={coupon} />}
                        </div>

                        <div className={classNames(mainContainerClassName, { 'coupon-banner': !!coupon })}>
                            <Sidebar
                                useStickyLayout={this.useStickyLayout}
                                customOrder={customOrder}
                                containerClassName={mainContainerClassName}
                                setRef={(ele) => {
                                    this.sidebar = ele;
                                }}
                                studio={studio}
                                seller={seller}
                            />

                            <div
                                className="main"
                                ref={(ele) => {
                                    this.main = ele;
                                }}
                            >
                                {/* need the inline style to override the div margin-bottom forced in gig page .main class, can be removed after the layout will be fixed */}
                                <Stack style={{ marginBottom: theme.spacing['7'] }} paddingTop="1.5">
                                    <Breadcrumbs />
                                </Stack>
                                {React.cloneElement(children, layoutProps)}
                            </div>
                        </div>

                        <MediaQuery query={`(max-width: ${MEDIA_GRID_MD})`}>
                            {(matches) => (
                                <FloatingContactSeller
                                    responseTime={responseTime}
                                    profilePhoto={profilePhoto}
                                    isMobileView={matches}
                                />
                            )}
                        </MediaQuery>

                        {this.shouldRenderBottomCarousels() && (
                            <aside className="gig-page-bottom">
                                <div className="max-width-container">
                                    <OtherGigsBy
                                        otherGigs={otherGigs}
                                        isLoggedIn={isLoggedIn}
                                        isTouch={isTouch}
                                        studioInfo={info}
                                    />
                                    {this.shouldRenderExplorePersonalizedSection() && (
                                        <PersonalizationWrapper
                                            sectionName={PersonalizationWrapper.SECTION_NAMES.EXPLORE}
                                            className="personalized-content-explore"
                                        />
                                    )}
                                </div>
                            </aside>
                        )}
                        <ReturningBuyerSubscriptionModal />
                        {collectionStepperPopup && <BusinessCollectionStepper />}
                        {showPromotionModal && (
                            <PromotionModal coupon={coupon} profilePhoto={profilePhoto} gigId={gigId} />
                        )}
                    </div>
                </Provider>
            </GigPageContextProvider>
        );
    }
}

GigPage.propTypes = {
    general: object,
    overview: object,
    currency: object,
    choiceEligibilities: array,
    limitedEdition: object,
    currentUser: object,
    tags: object,
    description: object,
    faq: object,
    reviews: object,
    sellerCard: object,
    rollouts: object,
    topNav: object,
    children: object,
    gallery: object,
    portfolio: object,
    customOrder: object,
    moreServices: object,
    otherGigs: object,
    coupon: object,
    seo: object,
    studio: object,
    repeatScore: object,
    workflow: object,
    notableClients: array,
    voiceOverAiAudition: object,
    packages: object,
    isTrustedFreelancer: bool,
    isFloatingChatEnabled: bool,
    promotionStatus: string,
    seller: object,
    highlightsData: object,
    instantOrderSettings: array,
    gigSellerTranslation: object,
    gigTheme: string,
    machineTranslationFromServer: object,
    newProBanner: object,
    promotedGigs: array,
    consultationData: ConsultationDataPropType,
    loyaltyIndication: LoyaltyIndicationPropType,
    aiModels: array,
    regionCode: string,
    sellerHourlyEligibility: SellerHourlyEligibilityPropType,
    isRestrictedPage: bool,
    buyer: object,
};

GigPage.defaultProps = {
    sellerCard: {},
    studio: {},
    description: {},
    limitedEdition: {},
    packages: {},
    rollouts: {},
    seller: {
        isVerified: false,
    },
    gigSellerTranslation: {},
    gigTheme: 'experiential',
    machineTranslationFromServer: {},
    aiModels: [],
    isRestrictedPage: false,
    buyer: {
        buyerId: '',
    },
    isFloatingChatEnabled: false,
};

GigPage.childContextTypes = {
    biEvents: object,
    logger: object,
    general: object,
    currencyConverter: object,
    pathfinderWrapper: object,
    choiceEligibilities: array,
    limitedEdition: object,
    currentUser: object,
    gig: object,
    seller: object,
    isBusinessLayout: bool,
    isProRebrandingLayout: bool,
    sellerLanguageParams: object,
    rollouts: object,
    clientExperimentsAllocation: object,
    gigSellerTranslation: object,
    gigTheme: string,
    machineTranslationFromServer: object,
    moreServices: object,
    coupon: object,
    sectionScroller: object,
    studio: object,
    consultationData: ConsultationDataPropType,
    loyaltyIndication: LoyaltyIndicationPropType,
    shouldDisplayFees: bool,
    aiModels: array,
    isFloatingChatEnabled: array,
    isRestrictedPage: bool,
    sellerCard: SellerCardPropType,
};

export default GigPage;
