import { getContext } from '@fiverr-private/fiverr_context';
import { ROLLOUTS } from '../../../types/rollouts';
import { HAWKS_PRICE_UPFRONT_V3 } from '../../experiments/experimentsEnum';
import { inExperiment } from '../../experiments';
import { getIsUserInCalifornia } from '../getIsUserInCalifornia/getIsUserInCalifornia';
import { GetShouldDisplayFees } from './getShouldDisplayFees.types';

export const getShouldDisplayFees = ({ abTests = {}, rollouts, regionCode }: GetShouldDisplayFees): boolean => {
    const { countryCode } = getContext();
    const isUserInCalifornia = getIsUserInCalifornia(countryCode, regionCode);

    return (
        isUserInCalifornia && rollouts[ROLLOUTS.HAWKS_PRICE_UPFRONT] && inExperiment(HAWKS_PRICE_UPFRONT_V3, abTests)
    );
};
